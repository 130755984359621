import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BLOCKS } from "@contentful/rich-text-types";

import { CUSTOM_TYPES } from "../../utils/renderRichText/TypesDefs/custom";
import { renderRichText } from "../../utils/renderRichText";
import Layout from "../../components/layout";
import PageInfo from "../../components/pageInfo";
import PageTopper from "../../components/pageTopper";

const Index = ({ data: { contentfulPage } }) => {
  let options = {
    renderFuncs: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <div>
          <GatsbyImage
            key={node.data.target.title}
            image={getImage(node.data.target.gatsbyImageData)}
            alt={node.data.target.title}
            style={{ maxHeight: "700px" }}
          />
        </div>
      ),
      [CUSTOM_TYPES.BUTTON_SINGLE]: (node, children) => (
        <div className="w-full flex-grow">{children}</div>
      ),
      [CUSTOM_TYPES.BUTTON]: (node, children) =>
        /http/.test(node.data.uri) ? (
          <a
            href={node.data.uri}
            className="w-full h-full btn btn-primary text-2xl xl:text-3xl font-new-tegomin"
            target="_blank"
            rel="noreferrer"
          >
            <span className="inline-flex h-full items-center">{children}</span>
          </a>
        ) : (
          <Link
            to={node.data.uri}
            className="w-full h-full btn btn-primary text-2xl xl:text-3xl font-new-tegomin"
          >
            <span className="inline-flex h-full items-center">{children}</span>
          </Link>
        ),
    },
  };

  let rawBody = JSON.parse(contentfulPage.body.raw);
  let currentListItem = 0;
  let list = rawBody.content.reduce((list, node) => {
    let { nodeType } = node;
    if (!list[currentListItem]) list[currentListItem] = {};
    if (
      nodeType === BLOCKS.EMBEDDED_ASSET &&
      !list[currentListItem].hasOwnProperty("image")
    ) {
      list[currentListItem]["image"] = renderRichText(
        { raw: node, references: contentfulPage.body.references },
        options
      );
    }
    if (
      nodeType === BLOCKS.PARAGRAPH &&
      !list[currentListItem].hasOwnProperty("link")
    ) {
      list[currentListItem]["link"] = renderRichText(
        { raw: node, references: contentfulPage.body.references },
        options
      );
    }
    if (nodeType === BLOCKS.HR) {
      currentListItem++;
    }

    return list;
  }, []);

  return (
    <Layout noContainer={!!contentfulPage.topperImage}>
      <PageInfo title={contentfulPage.title} />
      {contentfulPage.topperImage ? (
        <PageTopper
          image={getImage(contentfulPage.topperImage)}
          alt={contentfulPage.topperImage.title}
          className="mb-20"
        >
          <h1 className="text-6xl text-center mb-10 font-new-tegomin">
            {contentfulPage.title}
          </h1>

          <CategoriesList categories={list} />
        </PageTopper>
      ) : (
        <>
          <h1 className="text-6xl text-center mb-10 font-new-tegomin">
            {contentfulPage.title}
          </h1>

          <CategoriesList categories={list} />
        </>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($contentful_id: String!) {
    contentfulPage(contentful_id: { eq: $contentful_id }) {
      ...PageTemplateFields
    }
  }
`;

export default Index;

const CategoriesList = ({ categories }) => {
  return (
    <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8">
      {categories.map((category, index) => (
        <div key={index} className="flex flex-col gap-5 border p-5">
          {category.image}
          {category.link}
        </div>
      ))}
    </div>
  );
};
